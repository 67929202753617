(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/products-grid-widget/assets/javascripts/prepare-product-item.js') >= 0) return;  svs.modules.push('/components/sport/products-grid-widget/assets/javascripts/prepare-product-item.js');
"use strict";



const trinidad = svs.isServer ? require('trinidad-core').core : svs.core;

const {
  GridItemSizes
} = svs.isServer ? require('./constants.es6') : svs.sport.productsGridWidget.constants;
const {
  resolveProductId,
  productIds
} = svs.isServer ? trinidad.components.require('utils', 'products') : svs.utils.products;
const {
  getProductBrand
} = svs.isServer ? require('../../../../lb-utils/brand-mapping/assets/javascripts/brand-mapping.es6') : svs.components.lbUtils.brandMapping.fn;
const {
  getRacingClassName
} = svs.isServer ? trinidad.components.require('utils', 'racing') : svs.utils.racing;

async function prepareProductItem(_ref) {
  let {
    itemConfig,
    isMobile,
    size,
    trackingPrefix,
    widgetId,
    req
  } = _ref;
  if (size === GridItemSizes.LARGE) {
    throw new Error('prepareProductItem() failed: Size "large" not supported, no image-data in CMS-config');
  }
  const {
    branding,
    images,
    filterOpacity,
    gradientOpacity,
    title,
    subTitle,
    urlMapping,
    bannerText
  } = itemConfig;

  const {
    userSession
  } = svs.isServer ? req : svs.core;
  const isLoggedInAsAdmin = userSession.hasRole(userSession.roles.INTERNAL);
  const imageMobile = _chooseImage({
    images,
    isMobile: true,
    size
  });
  const imageDesktop = _chooseImage({
    images,
    isMobile: false,
    size
  });
  const displayBanner = typeof bannerText === 'string' && bannerText.trim() !== '';
  const _getWarningMessage = () => {
    const targetsWithoutImageData = [!imageMobile && 'mobile', !imageDesktop && 'desktop'].filter(Boolean);
    if (targetsWithoutImageData.length > 0) {
      return "Image missing for size \"".concat(size, "\" (").concat(targetsWithoutImageData.join(', '), ")");
    }
    if (!urlMapping) {
      return 'URL-mapping missing';
    }
    return null;
  };
  const warning = isLoggedInAsAdmin ? _getWarningMessage() : null;
  const trackingString = "".concat(trackingPrefix, "/single-product/").concat(branding);
  const productId = resolveProductId(branding.split('-secondary')[0]);
  let updatedBranding;
  if (productId === productIds.EUROPATIPSET) {
    updatedBranding = getProductBrand(productId);
  }
  if (productId === productIds.RACING) {
    updatedBranding = getRacingClassName(productIds.RACING, req);
    if (branding.includes('-secondary')) {
      updatedBranding = "".concat(updatedBranding, "-secondary");
    }
  }
  const productItem = {
    branding: updatedBranding || branding,
    imageMobile,
    imageDesktop,
    filterOpacity,
    gradientOpacity,
    title,
    subTitle,
    warning,
    urlMapping,
    size,
    trackingString,
    displayBanner,
    bannerText,
    isSecondSelectedProduct: false
  };
  return productItem;
}

function _chooseImage(_ref2) {
  let {
    images,
    isMobile,
    size
  } = _ref2;
  const imageCategory = {
    [GridItemSizes.SMALL]: isMobile ? 'mobileSmall' : 'desktopSmall',
    [GridItemSizes.MEDIUM]: isMobile ? 'mobileMedium' : 'desktopMedium',
    [GridItemSizes.SPECIAL_MEDIUM_OR_SMALL]: isMobile ? 'mobileMedium' : 'desktopSmall'
  }[size];
  return images[imageCategory];
}

if (svs.isServer) {
  module.exports = {
    prepareProductItem
  };
} else {
  setGlobal('svs.sport.productsGridWidget.prepareProductItem', prepareProductItem);
}

 })(window);